import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const Login = () => {
  return import('@/components/Login')
}

const TmsRouteList = () => {
  return import('@/components/TmsRouteList')
}

const TmsOrderList = () => {
  return import('@/components/TmsOrderList')
}

const TmsOrderDetail = () => {
  return import('@/components/TmsOrderDetail')
}

const TmsOrderTaskDetail = () => {
  return import('@/components/TmsOrderTaskDetail')
}

const TmsOrderTaskSignature = () => {
  return import('@/components/TmsOrderTaskSignature')
}

const TmsTaskSetStatus = () => {
  return import('@/components/TmsTaskSetStatus')
}

const TmsTaskSetStatusMultiple = () => {
  return import('@/components/TmsTaskSetStatusMultiple')
}

const Setting = () => {
  return import('@/components/Setting')
}

export default new Router({
  routes: [
    {
      path: `/`,
      menuname: `/login`,
      name: 'Login',
      component: Login
    },
    {
      path: `/tms/route/list`,
      menuname: `/tms/route/list`,
      name: 'TmsRouteList',
      component: TmsRouteList
    },
    {
      path: `/tms/order/list/:tms_route_id`,
      menuname: `/tms/order/list/tms/route/id`,
      name: 'TmsOrderList',
      component: TmsOrderList
    },
    {
      path: `/tms/order/detail/:tms_route_id/:tms_order_id/:tms_order_task_id`,
      menuname: `/tms/order/detail`,
      name: 'TmsOrderDetail',
      component: TmsOrderDetail
    },
    {
      path: `/tms/order/task/detail/:tms_route_id/:tms_order_id/:tms_order_task_id`,
      menuname: `/tms/order/task/detail`,
      name: 'TmsOrderTaskDetail',
      component: TmsOrderTaskDetail
    },
    {
      path: `/tms/order/task/signature/:tms_route_id/:tms_order_id/:tms_order_task_id`,
      menuname: `/tms/order/task/signature`,
      name: 'TmsOrderTaskSignature',
      component: TmsOrderTaskSignature
    },
    {
      path: `/tms/order/task/status/:tms_route_id/:tms_order_id/:tms_order_task_id`,
      menuname: `/tms/order/task/status`,
      name: 'TmsTaskSetStatus',
      component: TmsTaskSetStatus
    },
    {
      path: `/tms/multiple/order/task/status/:tms_route_id/:tms_order_task_id`,
      menuname: `/tms/multiple/order/task/status/multiple`,
      name: 'TmsTaskSetStatusMultiple',
      component: TmsTaskSetStatusMultiple
    },
    {
      path: `/setting`,
      menuname: `/setting`,
      name: 'Setting',
      component: Setting
    }
  ]
})
